import PimFetcher from '@/services/fetchers/Pim'
import ProductFetcher from '@/services/fetchers/Product'
import ResourceFetcher from '@/services/fetchers/Resource'
import EntityTypes from '@/constants/EntityTypes'
import { useUserStore } from '@/stores/user'
import { useRefStore } from '@/stores/ref'
import { useUniverseStore } from '@/stores/universe'
import { acceptHMRUpdate, defineStore, storeToRefs } from 'pinia'
import { computed, ref } from 'vue'
import _ from 'lodash'

export const useProductStore = defineStore('product', () => {
  const userStore = useUserStore()
  const { rights } = storeToRefs(userStore)

  const associations = ref(null)
  const product = ref(null)
  const fetchingAssociations = ref(false)
  const pimReferences = ref([])
  const loading = ref(false)
  const publishing = ref(false)
  const updating = ref(false)

  const canWrite = computed(() => rights.value?.product?.write ?? false)
  const canCreate = computed(() => rights.value?.product?.create ?? false)
  const canCreateSubProduct = computed(() => rights.value?.product?.createSubProduct ?? false)
  const canPublishUnpublish = computed(() => rights.value?.product?.publishUnpublish ?? false)

  const getAssociations = computed(() => associations.value)
  const getProduct = computed(() => product.value)
  const getFetchingAssociations = computed(() => fetchingAssociations.value)
  const getVariants = computed(() => product.value?.variants ?? [])
  const isPublished = computed(() => product.value?.refStatusId === refStatusIdActive.value || false)
  const isLoading = computed(() => loading.value)
  const getPimReferences = computed(() => pimReferences.value)
  const isUpdating = computed(() => updating.value)

  const refStatuses = computed(() => useRefStore().getRefs?.refStatuses ?? [])
  const refStatusActive = computed(() => refStatuses.value.find(f => f.slug === 'active'))
  const refStatusInactive = computed(() => refStatuses.value.find(f => f.slug === 'inactive'))
  const refStatusIdActive = computed(() => refStatusActive.value.id ?? null)
  const refStatusIdInactive = computed(() => refStatusInactive.value.id ?? null)

  const pimSlug = computed(() => {
    const universeStore = useUniverseStore()
    return universeStore.getSettingByPath('products.manifest.pim')['pim-slug']
  })
  const associationTypes = computed(() => {
    const universeStore = useUniverseStore()
    return universeStore.getSettingByPath('products.manifest.lieu.associations')
  })

  function clear () {
    product.value = null
    associations.value = null
    updating.value = false
    loading.value = false
    pimReferences.value = []
  }
  async function fetch (payload) {
    try {
      clear()
      loading.value = true
      const response = await fetchProduct(payload)
      setProduct(response.data.data)
      await fetchPimReferences({
        slug: pimSlug.value,
        key: 'pim_commun_type_societe',
        refLocaleCode: payload.params.locale
      })
    } finally {
      loading.value = false
    }
  }
  async function fetchAssociations ({ type, locale }) {
    try {
      fetchingAssociations.value = true
      const resp = await ProductFetcher.getAssociations(product.value.id, type, locale)
      const productPromises = resp.data.data.map(association => fetchProduct({
        params: { withUniversePimAttributes: 1, locale },
        productId: association.id
      }))
      const productResponses = await Promise.all(productPromises)
      associations.value = {
        ...associations.value,
        [type]: productResponses.map(pResp => pResp.data.data)
      }
    } finally {
      fetchingAssociations.value = false
    }
  }
  function fetchProduct ({ productId, params }) {
    return ResourceFetcher.getById(EntityTypes.Product, productId, params)
  }
  async function fetchPimReferences ({ slug, key, refLocaleCode }) {
    const references = (await PimFetcher.get(slug, { key, refLocaleCode }))?.data?.data ?? []
    pimReferences.value = _.unionBy(references, pimReferences.value, r => r.value)
    return references
  }
  async function saveProduct (payload) {
    setUpdating()
    try {
      const resp = await ProductFetcher.save(product.value.id, payload)
      setProduct(resp.data.data)
    } finally {
      setUpdating(false)
    }
  }
  function setProduct (payload) {
    product.value = {
      ...payload,
      variants: payload.variants ? payload.variants : product.value.variants
    }
  }
  function setUpdating (payload = true) {
    updating.value = payload
  }
  function setRefStatusId (refStatusId) {
    product.value = { ...product.value, refStatusId }
  }
  async function saveVariants (variants) {
    try {
      setUpdating()
      const resp = await ProductFetcher.saveVariants(product.value.id, variants)
      setProduct(resp.data.data)
    } finally {
      setUpdating(false)
    }
  }
  function publish () {
    setRefStatusId(refStatusIdActive.value)
  }
  function unpublish () {
    setRefStatusId(refStatusIdInactive.value)
  }
  async function toggleStatus (active) {
    try {
      setUpdating()
      publishing.value = true

      const status = active ? refStatusActive.value.slug : refStatusInactive.value.slug
      active ? publish() : unpublish()

      const resp = await ProductFetcher.setPublishStatus(product.value.id, status)
      if (!resp.data?.data?.success) {
        active ? unpublish() : publish()
      }
      return resp.data.data
    } catch (e) {
      active ? unpublish() : publish()
    } finally {
      setUpdating(false)
      publishing.value = false
    }
  }

  return {
    associations,
    product,
    updating,
    fetchingAssociations,
    loading,
    publishing,
    pimReferences,
    pimSlug,
    associationTypes,

    canWrite,
    canCreate,
    canCreateSubProduct,
    canPublishUnpublish,
    getAssociations,
    getProduct,
    getFetchingAssociations,
    getVariants,
    isPublished,
    isLoading,
    getPimReferences,
    isUpdating,
    refStatuses,
    refStatusIdActive,
    refStatusIdInactive,
    refStatusInactive,

    clear,
    fetch,
    fetchAssociations,
    fetchProduct,
    fetchPimReferences,
    saveProduct,
    setProduct,
    setUpdating,
    setRefStatusId,
    saveVariants,
    publish,
    unpublish,
    toggleStatus
  }
})

if (import.meta.webpackHot) {
  import.meta.webpackHot.accept(acceptHMRUpdate(useProductStore, import.meta.webpackHot))
}
